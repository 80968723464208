import React, { useState, useRef } from "react";
import ReadCategories from "./ReadCategories";
import ReadProduct from "./ReadProduct";
import "./UploadImages.css";

const UploadImages = ({ user }) => {
  const [files, setFiles] = useState([]);
  const [uploadMode, setUploadMode] = useState("individual"); // 'individual' o 'folder'
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]); // Solo la parte base64
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDropzoneClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedProduct({});
  };

  const handleProductChange = (product) => {
    setSelectedProduct(product);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (uploadMode === "zip") {
      if (files.length !== 1 || !files[0].name.endsWith(".zip")) {
        alert("Por favor selecciona un único archivo .zip");
        return;
      }
  
      try {
        const zipFile = files[0];
        const base64Zip = await convertToBase64(zipFile);
  
        const payload = {
          zip_base64: base64Zip,
        };
  
        const response = await fetch(
          "https://1e01glq9n4.execute-api.us-east-1.amazonaws.com/uploadimages",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
  
        const responseData = await response.json();
        if (response.ok) {
          alert("ZIP procesado correctamente");
          setFiles([]);
        } else {
          alert("Error al procesar el ZIP: " + (responseData.message || "Error desconocido"));
        }
      } catch (err) {
        console.error("Error procesando el ZIP:", err);
        alert("Error al convertir o enviar el ZIP");
      }
  
      return;
    }
  
    if (uploadMode === "folder") {
      const isFolderUpload = files.some(
        (file) =>
          file.webkitRelativePath && file.webkitRelativePath.includes("/")
      );
  
      if (!isFolderUpload) {
        alert("Por favor selecciona carpetas que representen IDs de producto.");
        return;
      }
  
      const groupedByProduct = {};
  
      for (let file of files) {
        const productId = file.webkitRelativePath.split("/")[0];
        const base64 = await convertToBase64(file);
        if (!groupedByProduct[productId]) groupedByProduct[productId] = [];
        groupedByProduct[productId].push({
          name: file.name,
          data: base64,
        });
      }
  
      const products = Object.entries(groupedByProduct).map(
        ([product_id, images]) => ({
          product_id,
          images,
        })
      );
  
      const payload = { products };
  
      try {
        const response = await fetch(
          "https://1e01glq9n4.execute-api.us-east-1.amazonaws.com/uploadimages",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
  
        const responseData = await response.json();
        if (response.ok) {
          alert("Imágenes subidas con éxito por carpetas.");
          setFiles([]);
        } else {
          alert(
            "Error en carga masiva: " +
              (responseData.message || "Error desconocido")
          );
        }
      } catch (err) {
        console.error(err);
      }
      return;
    }
  
    // Modo individual
    if (!selectedCategory.id || !selectedProduct.id) {
      alert("Por favor, selecciona una categoría y un producto.");
      return;
    }
  
    const images = await Promise.all(
      files.map(async (file) => ({
        name: file.webkitRelativePath || file.name,
        data: await convertToBase64(file),
      }))
    );
  
    const payload = {
      category_id: selectedCategory.id,
      product_id: selectedProduct.reference,
      images,
    };
  
    try {
      const response = await fetch(
        "https://1e01glq9n4.execute-api.us-east-1.amazonaws.com/uploadimages",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
  
      const responseData = await response.json();
      if (response.ok) {
        alert("Imágenes subidas con éxito");
        setFiles([]);
      } else {
        alert(
          "Error al subir imágenes: " +
            (responseData.message || "Error desconocido")
        );
      }
    } catch (error) {
      console.error("Error al subir las imágenes:", error);
    }
  };
  
  return (
    <div className="contenedor-document">
      <h1>Subir imágenes a productos</h1>

      <form className="upload-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="uploadMode">Modo de carga:</label>
          <select
            id="uploadMode"
            value={uploadMode}
            onChange={(e) => setUploadMode(e.target.value)}
            className="form-control"
          >
            <option value="individual">
              Actualizar imágenes de un producto
            </option>
            <option value="folder">
              Subir imágenes para múltiples productos (por carpetas)
            </option>
            <option value="zip">Subir ZIP con carpetas</option>
          </select>
        </div>

        {uploadMode === "individual" && (
          <>
            <ReadCategories onCategoryChange={handleCategoryChange} />
            <ReadProduct
              categoryId={selectedCategory.id}
              onProductChange={handleProductChange}
            />
          </>
        )}

        <div
          className={`dropzone ${isDragging ? "dragging" : ""}`}
          onClick={handleDropzoneClick}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          {uploadMode === "folder" ? (
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="form-control"
              multiple={uploadMode !== "zip"}
              accept={uploadMode === "zip" ? ".zip" : undefined}
              webkitdirectory={uploadMode === "folder" ? "true" : undefined}
              directory={uploadMode === "folder" ? "" : undefined}
              style={{ display: "none" }}
            />
          ) : (
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="form-control"
              multiple
              style={{ display: "none" }}
            />
          )}

          <span>Arrastra los archivos aquí o haz clic para seleccionar</span>
        </div>

        {files.length > 0 && (
          <div className="file-list">
            <h3>Archivos seleccionados:</h3>
            <ul>
              {files.map((file) => (
                <li key={file.webkitRelativePath || file.name}>
                  {uploadMode === "folder"
                    ? file.webkitRelativePath
                    : file.name}
                  <button
                    type="button"
                    onClick={() => handleRemoveFile(file.name)}
                  >
                    Eliminar
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        <button type="submit" className="btn-submit">
          Enviar
        </button>
      </form>
    </div>
  );
};

export default UploadImages;
